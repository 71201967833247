import { FC, memo } from 'react';

import { IonApp, IonSpinner } from '@ionic/react';

import styles from '../../App.module.css';

const Loader: FC = () => (
  <IonApp className={styles.ionApp}>
    <IonSpinner color="primary" className="spinner" />
  </IonApp>
);

export default memo(Loader);
