import { QuestionnaireTasksType, QuestionsProps, HistoryQuestionnaireTasksType } from 'interfaces';

export enum PatientTaskStatus {
  New = 'New',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Reopen = 'Reopen',
  Planned = 'Planned',
  Missed = 'Missed',
  AdverseEvent = 'AdverseEvent',
}

export interface TaskItemProps {
  id: string;
  title: QuestionnaireTasksType;
  titleView: string;
  date: string;
  dataTextKey: string;
  status: PatientTaskStatus;
  showPads?: boolean;
  disabled?: boolean;
  isMissed?: boolean;
  isDone?: boolean;
  isPlanned?: boolean;
  hasMissedMicturitionProtocol?: boolean;
}

export interface TaskProps {
  additionalProperties: {
    DueDate: string;
    micturitionProtocolPads?: string;
    micturitionProtocolId?: string;
  };
  createdDateTime: string;
  id: string;
  lastUpdatedDateTime: string;
  name: QuestionnaireTasksType;
  patientId: string;
  questionnaireType: QuestionnaireTasksType;
  status: PatientTaskStatus;
  hasMissedMicturitionProtocol?: boolean;
}

export interface AnswerProps {
  createdDateTime: string;
  id: string;
  lastUpdatedDateTime: string;
  local: string;
  patientId: string;
  questionnaireId: string;
  questionnaireType: QuestionnaireTasksType & HistoryQuestionnaireTasksType;
  questionnaireVersion: string;
  questions: QuestionsProps[];
}

export interface PlannedProps {
  dueDate: string;
  name: QuestionnaireTasksType;
  status: PatientTaskStatus;
}

export enum MicturitionHistoryQuestionsEnum {
  startDate = 'startDate',
  endDate = 'endDate',
  micturitionProtocolId = 'micturitionProtocolId',
}
