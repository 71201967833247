import AppStore from './AppStore.svg';
import ArrowSelect from './ArrowSelect.svg';
import Back from './Back.svg';
import BladderProtocol from './BladderProtocol.svg';
import Cancel from './Cancel.svg';
import Check from './Check.svg';
import Clock from './Clock.svg';
import Close from './Close.svg';
import CloseEye from './CloseEye.svg';
import DailyToilet from './DailyToilet.svg';
import DailyToiletBladder from './DailyToiletBladder.svg';
import Delete from './Delete.svg';
import Edit from './Edit.svg';
import Ellipse from './Ellipse.svg';
import ErrorPage from './ErrorPage.png';
import FinishLogo from './FinishLogo.svg';
import Frame from './Frame.svg';
import GooglePlay from './GooglePlay.png';
import Info from './Info.svg';
import LogOut from './LogOut.svg';
import Logo from './Logo.svg';
import NounPlus from './NounPlus.svg';
import OpenEye from './OpenEye.svg';
import Pad from './Pad.svg';
import Questionnaire from './Questionnaire.svg';
import ResetPassword from './ResetPassword.svg';
import RightArrow from './RightArrow.svg';
import Satisfied from './Satisfied.png';
import UrineLossPadTest from './UrineLossPadTest.svg';
import Water from './Water.svg';
import WhiteCheck from './WhiteCheck.svg';

export {
  GooglePlay,
  AppStore,
  Edit,
  Delete,
  CloseEye,
  OpenEye,
  Close,
  Ellipse,
  FinishLogo,
  Cancel,
  Water,
  Check,
  Clock,
  Frame,
  Pad,
  BladderProtocol,
  NounPlus,
  Questionnaire,
  RightArrow,
  UrineLossPadTest,
  LogOut,
  Back,
  ErrorPage,
  DailyToilet,
  WhiteCheck,
  ResetPassword,
  ArrowSelect,
  Info,
  DailyToiletBladder,
  Satisfied,
  Logo,
};

export const ImagesArray = [
  GooglePlay,
  AppStore,
  Edit,
  Delete,
  CloseEye,
  OpenEye,
  Close,
  Ellipse,
  FinishLogo,
  Cancel,
  Water,
  Check,
  Clock,
  Frame,
  Pad,
  BladderProtocol,
  NounPlus,
  Questionnaire,
  RightArrow,
  UrineLossPadTest,
  LogOut,
  Back,
  ErrorPage,
  DailyToilet,
  WhiteCheck,
  ResetPassword,
  ArrowSelect,
  Info,
  DailyToiletBladder,
  Satisfied,
  Logo,
];
