import React, { Suspense, lazy, useEffect, useRef, FC, useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import { IonApp, IonContent } from '@ionic/react';

import Loader from 'components/Loader';
import 'localization/i18n';
import { ROUTES } from 'routes';
import { cacheImages } from 'services';

import styles from './App.module.css';
import PolicyLinks from './components/PolicyLinks';
import { AppProps } from './interfaces';

const QuestionnaireCreate = lazy(() => import('pages/QuestionnaireWrapper/Create'));
const QuestionnaireView = lazy(() => import('pages/QuestionnaireWrapper/View'));
const ChangePassword = lazy(() => import('pages/ChangePassword'));
const Onboarding = lazy(() => import('pages/Onboarding'));
const Error = lazy(() => import('pages/Error'));
const Tasks = lazy(() => import('pages/Tasks'));
const Impressum = lazy(() => import('pages/Policy/Impressum'));
const DataProtectionPolicy = lazy(() => import('pages/Policy/DataProtectionPolicy'));

const App: FC<AppProps> = ({ isOnboarding, setIsOnboarding }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const ref = useRef(false);

  const { isAuthenticated, isLoading, signinRedirect } = auth;

  const isPolicyPage = useMemo(() => {
    return pathname === ROUTES.impressum || pathname === ROUTES.dataProtectionPolicy;
  }, [pathname]);

  useEffect(() => {
    if (isPolicyPage) return;

    if (isLoading || ref.current) return;

    if (!isAuthenticated) {
      ref.current = true;
      signinRedirect();
    }
    // eslint-disable-next-line
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    if (auth.error) {
      navigate(ROUTES.error);
    }
  }, [auth.error, navigate]);

  useEffect(() => {
    cacheImages();
  }, []);

  if (auth.isLoading) {
    return <Loader />;
  }

  return (
    <React.StrictMode>
      <Suspense fallback={<Loader />}>
        <IonApp className={styles.ionApp}>
          <IonContent>
            {!isAuthenticated ? (
              <Routes>
                <Route path={ROUTES.error} element={<Error />} />
                <Route path={ROUTES.impressum} element={<Impressum />} />
                <Route path={ROUTES.dataProtectionPolicy} element={<DataProtectionPolicy />} />
                <Route path="*" element={<Navigate to={ROUTES.error} />} />
              </Routes>
            ) : isOnboarding ? (
              <Routes>
                <Route
                  path={ROUTES.onboarding}
                  element={
                    <Onboarding setIsOnboarding={setIsOnboarding} isOnboarding={isOnboarding} />
                  }
                />
                <Route path={ROUTES.impressum} element={<Impressum />} />
                <Route path={ROUTES.dataProtectionPolicy} element={<DataProtectionPolicy />} />
                <Route path={ROUTES.error} element={<Error />} />
                <Route path="*" element={<Navigate to={ROUTES.onboarding} />} />
              </Routes>
            ) : (
              <Routes>
                <Route path={ROUTES.default} element={<Tasks />} />
                <Route path={ROUTES.changePassword} element={<ChangePassword />} />
                <Route
                  path={ROUTES.onboarding}
                  element={
                    <Onboarding setIsOnboarding={setIsOnboarding} isOnboarding={isOnboarding} />
                  }
                />
                <Route path={ROUTES.error} element={<Error />} />
                <Route path={ROUTES.questionnaire} element={<QuestionnaireCreate />} />
                <Route path={ROUTES.questionnaireView} element={<QuestionnaireView />} />
                <Route path={ROUTES.impressum} element={<Impressum />} />
                <Route path={ROUTES.dataProtectionPolicy} element={<DataProtectionPolicy />} />
                <Route path="*" element={<Navigate to={ROUTES.default} />} />
              </Routes>
            )}
          </IonContent>
          {!isPolicyPage && <PolicyLinks />}
        </IonApp>
      </Suspense>
    </React.StrictMode>
  );
};

export default App;
