const deMessages = {
  translation: {
    portalTitle: 'Klinisches Studienportal',
    impressum: 'Impressum',
    dataProtectionPolicy: 'Datenschutz',
    logout: 'Abmelden',
    logoutConfirm: 'Sind Sie sicher, dass Sie sich abmelden möchten?',
    cancel: 'Abbrechen',
    urineLossPadTest: 'Urinverlust-Test',
    micturitionProtocol: 'Blasenprotokoll',
    micturitionProtocolHistory: 'Blasenprotokoll',
    micturitionProtocolReopen:
      'Sie haben 2 Tage lang das Blasenprotokoll nicht ausgefüllt. Sie müssen das Blasenprotokoll deswegen leider von neuem beginnen.',
    ICIQOAB: 'Fragebogen Überaktive Blase',
    ICIQOABqoL: 'Fragebogen Überaktive Blase - Lebensqualität',
    ICIQ: 'Fragebogen Harninkontinenz',
    installApp: 'INKA App installieren',
    New: 'Zu erledigen',
    InProgress: 'Angefangen',
    Completed: 'Erledigt',
    Reopen: 'Zu erledigen',
    Planned: 'Geplant',
    Missed: 'Verpasst',
    dueDate: 'Zu erledigen bis: ',
    completed: 'Erledigt: ',
    planned: 'Geplant: ',
    plannedDate: 'Verfügbar ab: ',
    start: 'Start',
    finish: 'Fertigstellen',
    grams: 'Gramm',
    seeResult: 'Siehe Ergebnis',
    back: 'Zurück',
    continue: 'Weiter',
    edit: 'Bearbeiten',
    save: 'Speichern',
    send: 'Speichern',
    yes: 'Ja',
    no: 'Nein',
    next: 'Weiter',
    toHome: 'Zurück zur Startseite',
    gotIt: 'Verstanden',
    required: 'Bitte beantworten Sie die Frage!',
    contradictsAnswer:
      'Bitte überprüfen Sie Ihre Antwort, da sie im Widerspruch zu anderen Antworten steht.',
    rangeDescription:
      'Bitte wählen Sie eine Zahl zwischen {{minVal}} ({{minText}}) und {{maxVal}} ({{maxText}})',
    micturitionProtocolPage: {
      incointentAidsUsed: 'verwendete Inkontinenzhilfen',
      incontinenceAids: 'Inkontinenzhilfen',
      name: 'Blasenprotokoll',
      urinated: 'Uriniert',
      pads: 'Einlagen',
      numberOfPadsPerDay: 'Anzahl der Inkontinenzhilfen pro Tag',
      countMlOfUrine: '{{ml}} ml Urin',
      levelOfUrge: '{{level}} - Stärke des Harndrangs',
      addMicturition: '+ Hinzufügen eines Toilettenganges',
      reviewYourData: 'Überprüfen Sie Ihre Daten',
      noun: '{{count}}',
      times: '{{count}} Mal',
      ml: '{{count}} ml',
      averageMicturitionsDay: 'ø Toilettengänge pro Tag:  6:00 – 22:00',
      averageMicturitionsNight: 'ø Toilettengänge pro Nacht: 22:00 – 6:00',
      averageMicturitions: 'ø Toilettengänge pro 24 h, 3 Tage',
      minimumUrine: 'Minimale Urinmenge pro Toilettengang [ml]',
      maximumUrine: 'Maximale Urinmenge pro Toilettengang [ml]',
      averageVolume: 'ø Urinmenge pro Toilettengang [ml]',
      averagePads: 'ø Anzahl der Einlagen pro Tag (Typ: {{type}})',
      averageBladderSensation: 'ø Blasengefühl',
      confirmRemoveUrinated: 'Möchten Sie den Datensatz entfernen {{date}}?',
      info: {
        title: 'Wie stark ist Ihr Drang?',
        0: '0 - Wenn Sie keinen Harndrang verspürt haben, aber aus "sozialen Gründen" zur Toilette sind, zum Bsp. kurz vor dem Ausgehen, oder Unsicherheit über die Verfügbarkeit der nächsten Toilette.',
        1: '1 - Wenn Sie einen normalen Harndrang verspürt haben und keine <b>Dringlichkeit.</b> "Dringlichkeit" unterscheidet sich von normalem Blasenempfinden und ist das plötzliche, zwingende Verlangen zu urinieren, das schwer aufzuschieben ist, oder ein plötzliches Gefühl urinieren zu müssen und wenn Sie es nicht tun, werden Sie ein Missgeschick haben.',
        2: '2 - Wenn Sie Dringlichkeit verspürt haben, diese aber abgeklungen ist, bevor Sie auf die Toilette gingen.',
        3: '3 - Wenn Sie Dringlichkeit verspürt haben, aber rechtzeitig zur Toilette sind, immer noch mit Dringlichkeit, aber ohne ungewollten Urinverlust.',
        4: '4 - Wenn Sie Dringlichkeit verspürt und es nicht rechtzeitig zur Toilette geschafft haben und deshalb ungewollt Urin verloren haben.',
      },
    },
    tasks: {
      activitiesToComplete: 'Heute zu erledigende Aufgaben',
      activitiesToCompleteDescription: 'Bitte denken Sie auch an Ihre Aufgaben in der INKA-App.',
      activitiesToCompleteEmpty: 'Für heute ist nichts geplant.',
      history: 'Verlauf',
      planned: 'Geplant',
      plannedEmpty:
        'Herzlichen Glückwunsch, Sie sind fast fertig! <br /> Es gibt keine weiteren zukünftigen Aufgaben.',
      adverseEventTitle: 'Medizinische Vorfälle',
      adverseEvent: 'Eingabe medizinische Vorfälle',
      resetPassword: 'Kennwort zurücksetzen',
      tooltip: 'Aufgaben werden ab dem angegebenen Zeitpunkt verfügbar. Sie können jedoch die Aufgabe zu einem für Sie günstigen Zeitpunkt innerhalb der folgenden Tage beginnen, z.B. am Morgen des folgenden Tages. Bitte verschieben Sie den Start aber nicht zu lange, da die Aufgabe nur eine begrenzte Zeit verfügbar ist.'
    },
    errorPage: {
      title: 'Oje!',
      description:
        'Es ist ein Fehler aufgetreten. Der Vorgang konnte nicht durchgeführt werden. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie die Dr. Pfleger Hotline!',
    },
    urineLossPadTestPage: {
      water: 'Wasser',
      fruitTea: 'Früchtetee',
      coffee: 'Kaffee',
      blackTea: 'Schwarztee',
      emptyWeightError: 'Bitte geben Sie das ermittelte Gewicht in Gramm ein!',
      minWeightError:
        'Das Gewicht darf nicht geringer sein als das Gewicht der trockenen Einlage ({{grams}}g). Bitte kontrollieren Sie Ihre Eingabe!',
      result:
        'Ergebnis: Nettogewicht des Urinverlusts in Milliliter = Endgewicht der Einlage minus Leergewicht der Einlage in Gramm.',
      resultView: 'Ergebnis: Nettogewicht des Urinverlustes beträgt {{grams}} Gramm.',
      great: 'Sehr gut!',
      wellDone: 'Sie sind fertig!',
      lossWeight: 'Sie haben bei diesem Test <strong>{{grams}} ml</strong> Urin verloren! ',
      alertHeader: 'Möchten Sie zurückgehen?',
      alertMessage:
        'Die von Ihnen bisher eingegebenen Daten werden nicht gespeichert. Sie müssen den Test von Anfang an beginnen.',
      label: {
        emptyWeight: 'Gewicht der Einlage, Gramm',
        finalWeight: 'Gewicht der Einlage nach den Übungen, Gramm',
      },
    },
    changePassword: {
      title: 'Kennwort ändern',
      description: 'Bitte geben Sie eine neues Kennwort <br /> für das Klinische Studienportal ein',
      currentPassword: 'Bisheriges Kennwort',
      currentPasswordPlaceholder: 'Geben Sie Ihr bisheriges Kennwort ein',
      newPassword: 'Neues Kennwort',
      newPasswordPlaceholder: 'Geben Sie ein Kennwort ein',
      confirmPassword: 'Kennwort bestätigen',
      confirmPasswordPlaceholder: 'Bestätigen Sie das Kennwort.',
      confirmSuper: 'Super!',
      confirmDescription: 'Ihr Kennwort wurde geändert.',
      passwordHint:
        'Das Kennwort sollte mindestens 8 Zeichen lang sein und eine Großbuchstaben, einen Kleinbuchstaben und ein Sonderzeichen beinhalten.',
      emptyCurrentPassword: 'Bitte geben Sie Ihr bisheriges Kennwort ein!',
      errorCurrentPassword: 'Das Kennwort ist falsch. Bitte versuchen Sie es erneut!',
      errorNewPassword: 'Bitte geben Sie ein neues Kennwort ein!',
      errorConfirmPassword: 'Bitte bestätigen Sie Ihr neues Kennwort!',
      errorMatchPasswords:
        'Die eingegebenen Kennwörter stimmen nicht überein. Bitte versuchen Sie es erneut!',
    },
    onboarding: {
      title: 'Willkommen zum klinischen Studienportal!',
      description:
        'Danke, dass Sie an dieser Studie teilnehmen. <br/> ' +
        'Sie wurden der INKA-Gruppe zugeteilt.<br/><br/>' +
        'Das bedeutet, Sie werden im Laufe der Studie mit diesem Studienportal arbeiten und zusätzlich die INKA-App benutzen.<br/>',
      buttonName: 'OK',
    },
    installAppTask: {
      title: 'Bitte holen Sie sich die INKA App vom Google Playstore (Android) oder App Store (iOS) und installieren es auf Ihrem Gerät. Dazu können Sie folgende Links benutzen:',
      subTitle: '(Sollte der Link für Sie nicht funktionieren, öffnen Sie Ihren App Store und suchen Sie dort nach INKA.)',
      helperText: "Nachdem Sie INKA installiert haben, melden Sie sich bitte direkt an. Sie brauchen kein neues Konto mehr registrieren. Um sich in INKA anzumelden, benutzen Sie bitte dieselbe E-Mail-Adresse und dasselbe Kennwort, die sie hier im Studienportal verwendet haben.",
      helperSubText: "Nachdem Sie die App installiert haben, bestätigen Sie bitte die Installation, indem Sie auf ‘Ich bestätige die Installation’ drücken.",
      buttonName: 'Ich bestätige die Installation',
    }
  },
};

export default deMessages;
