import axios, { AxiosInstance } from 'axios';

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers: {
    'Accept-Language': process.env.REACT_APP_LNG || 'en-en',
  },
});

export default api;
