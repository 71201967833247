import { useState, useCallback } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';

import { setupIonicReact } from '@ionic/react';
import '@ionic/react/css/core.css';
import '@ionic/react/css/flex-utils.css';
//
// /* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
//
// /* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/typography.css';

import { API_URLS } from 'services';
import api from 'services/axios.instance';

import App from './App';
import './index.css';
import { PatientTaskStatus } from './pages/Tasks/interfaces';

import { User, WebStorageStateStore } from 'oidc-client-ts';

setupIonicReact({
  rippleEffect: false,
  mode: 'ios',
});

const user = new WebStorageStateStore({ store: localStorage });


const configuration = {
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID || '',
  redirect_uri: window.location.origin,
  responseType: 'code',
  scope: 'treatment',
  authority: process.env.REACT_APP_AUTH_URL || '',
  userStore: user,
  stateStore: user,
};

export const AppWrapper = () => {
  const [isOnboarding, setIsOnboarding] = useState(false);

  const onSigninCallback = useCallback(async (userData: User | void) => {
    const authorization = `${userData?.token_type} ${userData?.access_token}`;
    const result = await api.request({
      headers: { authorization },
      method: 'GET',
      url: API_URLS.onboarding,
    });

    if (
      result?.data &&
      result?.data?.length &&
      result?.data?.[0]?.status === PatientTaskStatus.New
    ) {
      setIsOnboarding(true);
    }

    window.history.replaceState({}, document.title, window.location.origin);
  }, []);

  return (
    <AuthProvider {...configuration} onSigninCallback={onSigninCallback}>
      <BrowserRouter>
        <App isOnboarding={isOnboarding} setIsOnboarding={setIsOnboarding} />
      </BrowserRouter>
    </AuthProvider>
  );
};
