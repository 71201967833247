import { AxiosRequestConfig } from 'axios';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'routes';

import api from './axios.instance';
import { UseAxiosProps, FetchDataProps } from './interfaces';

export const useAxios = <T>({ params, preUpload = true }: UseAxiosProps) => {
  const [response, setResponse] = useState<T | undefined>(undefined);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(preUpload);
  const auth = useAuth();
  const navigate = useNavigate();
  const ref = useRef(false);

  const fetchData = useCallback(
    async ({
      axiosParams = {},
      errorCallback,
      errorNavigate = true,
      withDefaultNavigate = true,
      showLoading = true,
    }: FetchDataProps) => {
      try {
        setLoading(showLoading);

        const authorization = `${auth.user?.token_type} ${auth.user?.access_token}`;

        const result = await api.request({
          headers: { authorization, ...axiosParams.headers },
          ...axiosParams,
        });

        setResponse(result.data);

        if (result.status === 204 && withDefaultNavigate) {
          navigate(ROUTES.default);
        }

        return result.data;
      } catch (err) {
        setError(err?.message);

        if (err?.response?.status === 401) {
          await auth.signoutRedirect();
        }

        errorCallback?.(err);

        if (errorNavigate) {
          navigate(ROUTES.error);
        }
      } finally {
        setLoading(false);
      }
    },
    [navigate, auth]
  );

  const refetch = useCallback(
    async (refetchParams: AxiosRequestConfig = {}) => {
      await fetchData({
        axiosParams: { ...params, ...refetchParams },
        showLoading: false,
      });
    },
    [fetchData, params]
  );

  useEffect(() => {
    if (!preUpload || ref.current) return;
    ref.current = true;
    fetchData({ axiosParams: params }).catch((err) => console.error(err));
    // eslint-disable-next-line
  }, []);

  return { response, error, loading, fetchData, refetch };
};
