import { ImagesArray } from 'assets';

export const cacheImages = async () => {
  const promises = ImagesArray.map((src) => {
    return new Promise(function (resolve, reject) {
      const img = new Image();

      img.addEventListener('load', () => {
        resolve(img);
      });

      img.addEventListener('error', (error) => {
        reject(error);
      });

      img.src = src;
    });
  });

  await Promise.all(promises);
};
