import { QuestionnaireTasksType, BladderProtocolType } from 'interfaces';
import dayjs from 'dayjs';

const currentData = dayjs().format('YYYY-MM-DDTHH:mm')

export const API_URLS = {
  onboarding: '/tasks?name=firstLogin',
  postOnboarding: '/tasks/firstLogin',
  tasks: `/volunteer/tasks?currentDate=${currentData}`,
  plannedTasks: '/volunteer/tasks/plan',
  answers: '/answers',
  getBladderProtocol: (id: string) => `/volunteers/bladder/${id}`,
  getAnswer: (id: string) => `/answers/${id}`,
  questionnaire: (type?: QuestionnaireTasksType | BladderProtocolType) =>
    `/questionnaires?type=${type}&version=latest`,
};

export const API_URLS_IDENTITY_SERVICES = {
  changePassword: '/api/v1/accounts/changePassword',
  accounts: '/api/v1/accounts',
  passwordValidation: '/api/v1/validation/password',
};
