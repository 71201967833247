import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ROUTES } from 'routes';

import styles from './styles.module.css';

const PolicyLinks = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      <Link to={ROUTES.dataProtectionPolicy} className={styles.link}>
        {t('dataProtectionPolicy')}
      </Link>
      <Link to={ROUTES.impressum} className={styles.link}>
        {t('impressum')}
      </Link>
    </div>
  );
};

export default memo(PolicyLinks);
