export const ROUTES = {
  default: '/',
  error: '/error',
  questionnaires: 'questionnaires',
  questionnaire: 'questionnaires/:type',
  questionnaireView: 'questionnaires/:type/:id',
  changePassword: 'change-password',
  onboarding: '/onboarding',
  impressum: '/impressum',
  dataProtectionPolicy: '/dataProtectionPolicy',
  installApp: '/installApp',
};

export const HealthCheckRouter = '/healthCheck';
