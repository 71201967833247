const enMessages = {
  translation: {
    portalTitle: 'Clinical Study Portal',
    impressum: 'Impressum',
    dataProtectionPolicy: 'Data Protection Policy',
    logout: 'Logout',
    logoutConfirm: 'Are you sure you want to logout?',
    cancel: 'Cancel',
    urineLossPadTest: 'Urine Loss Pad Test',
    micturitionProtocol: 'Bladder Protocol',
    micturitionProtocolHistory: 'Bladder Protocol',
    micturitionProtocolReopen:
      'You have missed 2 days of the micturition protocol and that is why you need to restart it.',
    ICIQOAB: 'Questionnaire Overactive Bladder',
    ICIQOABqoL: 'Questionnaire Overactive Bladder - Quality of Life',
    ICIQ: 'Questionnaire Urinary Incontinence',
    installApp: 'INKA App installieren',
    New: 'To Do',
    InProgress: 'Ongoing',
    Completed: 'Done',
    Reopen: 'To Do',
    Planned: 'Planned',
    Missed: 'Missed',
    dueDate: 'Due date: ',
    completed: 'Completed: ',
    planned: 'Planned: ',
    plannedDate: 'Planned: ',
    start: 'Start',
    finish: 'Finish',
    grams: 'grams',
    seeResult: 'See Result',
    back: 'Back',
    continue: 'Continue',
    edit: 'Edit',
    save: 'Save',
    send: 'Send',
    yes: 'Yes',
    no: 'No',
    next: 'Next',
    toHome: 'Back to Home',
    gotIt: 'Got It',
    required: 'Please answer the question.',
    contradictsAnswer: 'Please review your answer because it contradicts with other answer.',
    rangeDescription:
      'Please ring a number between {{minVal}} ({{minText}}) and {{maxVal}} ({{maxText}})',
    micturitionProtocolPage: {
      incointentAidsUsed: 'Incontinence aids used',
      incontinenceAids: 'Incontinence aids',
      name: 'Bladder Protocol',
      urinated: 'Urinated',
      pads: 'Pads',
      numberOfPadsPerDay: 'Number of pads per day',
      countMlOfUrine: '{{ml}} ml of urine',
      levelOfUrge: '{{level}} - level of Urge',
      addMicturition: '+ Add Micturition',
      reviewYourData: 'Review your data',
      noun: '{{count}}',
      times: '{{count}} times',
      ml: '{{count}} ml',
      averageMicturitionsDay: 'ø Micturitions per day: 6.00 - 22.00',
      averageMicturitionsNight: 'ø Micturitions per night: 22.00 - 6.00',
      averageMicturitions: 'ø Micturitions 24 h, 3 days',
      minimumUrine: 'Minimum urine volume per micturition [ml]',
      maximumUrine: 'Maximum urine volume per micturition [ml]',
      averageVolume: 'ø Urine volume per micturition [ml]',
      averagePads: 'ø No. of pads per day (type: {{type}})',
      averageBladderSensation: 'ø Bladder sensation',
      confirmRemoveUrinated: 'Do you want to remove the record {{date}}?',
      info: {
        title: 'How did your bladder feel before you went to the toilet?',
        0: '0 - No sensation of needing to pass urine, but passed urine for "social reasons"',
        1: '1 – Normal desire to pass urine and no urgency',
        2: '2 – Urgency but it had passed away before you went to the toilet',
        3: '3 – Urgency but managed to get to the toilet, still with urgency, but did not leak urine',
        4: '4 – Urgency and could not get to the toilet in time so you leaked urine',
      },
    },
    tasks: {
      activitiesToComplete: 'Activities to complete now',
      activitiesToCompleteDescription: 'Bitte denken Sie auch an Ihre Aufgaben in der INKA-App.',
      activitiesToCompleteEmpty: 'Nothing is scheduled for today.',
      history: 'History',
      planned: 'Planned',
      plannedEmpty:
        'Congrats, you are almost done! <br /> Nothing is scheduled for the future dates.',
      adverseEventTitle: 'Medical Incidents',
      adverseEvent: 'Medical Incidents',
      resetPassword: 'Reset password',
      tooltip: 'Aufgaben werden ab dem angegebenen Zeitpunkt verfügbar. Sie können jedoch die Aufgabe zu einem für Sie günstigen Zeitpunkt innerhalb der folgenden Tage beginnen, z.B. am Morgen des folgenden Tages. Bitte verschieben Sie den Start aber nicht zu lange, da die Aufgabe nur eine begrenzte Zeit verfügbar ist.'
    },
    errorPage: {
      title: 'Oh, no',
      description:
        'Something went wrong. Please check your internet connection and try your action again later',
    },
    urineLossPadTestPage: {
      water: 'Water',
      fruitTea: 'Fruit tea',
      coffee: 'Coffee',
      blackTea: 'Black Tea',
      emptyWeightError: 'Please enter a valid weight in grams',
      minWeightError:
        'The weight cannot be less than the weight of the dry insert ({{grams}} g). Please enter the correct weight.',
      result:
        'Result: Net weight of urine loss in milliliters = final weight of insert minus empty weight of insert in grams.',
      resultView: 'Result: Net weight of urine loss is {{grams}} grams',
      great: 'Great!',
      wellDone: 'You are done!',
      lossWeight: 'Net weight of urine loss is: <strong>{{grams}} grams.</strong>',
      alertHeader: 'Do you want to go back?',
      alertMessage:
        'Data you entered so far will not be saved. You will have to start the test from the beginning.',
      label: {
        emptyWeight: 'Weight of the empty pad, grams',
        finalWeight: 'Weight of the pad after exercises, grams',
      },
    },
    changePassword: {
      title: 'Change Password',
      description: 'Please enter a new password <br /> for Clinical Study Portal',
      currentPassword: 'Current Password',
      currentPasswordPlaceholder: 'Enter your Current Password',
      newPassword: 'New Password',
      newPasswordPlaceholder: 'Create a Password',
      confirmPassword: 'Confirm Password',
      confirmPasswordPlaceholder: 'Confirm the Password',
      confirmSuper: 'Super!',
      confirmDescription: 'Your password has been changed.',
      passwordHint:
        'Password should be at least 8 characters and one uppercase letter, one lowercase letter, and one special character.',
      emptyCurrentPassword: 'Please enter your current password',
      errorCurrentPassword: 'Current password is not correct. Please try again.',
      errorNewPassword: 'Please enter your new password',
      errorConfirmPassword: 'Please confirm your new password',
      errorMatchPasswords: 'Passwords you entered did not match. Please try again.',
    },
    onboarding: {
      title: 'Willkommen zum klinischen Studienportal!',
      description:
        'Danke, dass Sie an dieser Studie teilnehmen. <br/> ' +
        'Sie wurden der INKA-Gruppe zugeteilt.<br/><br/>' +
        'Das bedeutet, Sie werden im Laufe der Studie mit diesem Studienportal arbeiten und zusätzlich die INKA-App benutzen.<br/>',
      buttonName: 'Ok',
    },
    installAppTask: {
      title: 'Bitte holen Sie sich die INKA App vom Google Playstore (Android) oder App Store (iOS) und installieren es auf Ihrem Gerät. Dazu können Sie folgende Links benutzen:',
      subTitle: '(Sollte der Link für Sie nicht funktionieren, öffnen Sie Ihren App Store und suchen Sie dort nach INKA.)',
      helperText: "Nachdem Sie INKA installiert haben, melden Sie sich bitte direkt an. Sie brauchen kein neues Konto mehr registrieren. Um sich in INKA anzumelden, benutzen Sie bitte dieselbe E-Mail-Adresse und dasselbe Kennwort, die sie hier im Studienportal verwendet haben.",
      helperSubText: "Nachdem Sie die App installiert haben, bestätigen Sie bitte die Installation, indem Sie auf ‘Ich bestätige die Installation’ drücken.",
      buttonName: 'Ich bestätige die Installation',
    }
  },
};

export default enMessages;
